@import "./mediaquery";
@import "./variables";

.stats {
  padding: 1em;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .welcome {
    border-bottom: 1px solid #60606030;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;

    .refresh {
      width: 32px;
      height: 32px;
      color: $accent;
      cursor: pointer;
      padding: 5px;
      background-color: white;
      border-radius: 5px;
    }

    .refresh:hover {
      color: $primary;
    }

    h1 {
      font-size: x-large;
      margin-top: 5px;
      margin-bottom: 10px;
      color: $primary;
    }

    .search {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 10px;

      .select {
        select {
          margin: auto;
          background-color: #60606020;
          padding: 1em;
          border: none;
          outline: none;
          border-radius: 44px;
          height: 44px;
        }
      }

      input {
        background-color: #60606020;
        padding: 1em;
        border: none;
        outline: none;
        border-radius: 44px;
        height: 44px;
      }

      i {
        padding: 1em;
        color: orange;
        height: 44px;
        margin-left: -54px;
      }

      input:focus {
        outline: 1px solid orange;
      }
    }
  }

  .taskstats {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    .bar {
      display: grid;
      grid-template-columns: 10px 1fr;
      background-color: white;
      box-shadow: 1px 1px 1px $primary;

      div {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }
    }

    .section {
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 1px 1px 3px #60606030;
      padding: 10px;
      position: relative;

      .br{
        width: 8px;
        height: 100%;
        background-color: $accent;
        position: absolute;
        left: 0;
        top: 0;
      }

      h3 {
        text-align: center;
        margin-bottom: 10px;
        font-size: medium;
        font-weight: 500;
        color: gray;
      }

      .gauge {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;

        .gg {
          display: block;
          margin-top: auto;
        }
      }

      .single {
        display: grid;
        grid-template-columns: auto 1fr;
        place-content: center;
        gap: 10px;
        margin: auto;
        height: 100%;

        .ts {
          width: 44px;
          height: 44px;
          color: $accent;
          display: block;
          margin: auto;
        }

        h4 {
          font-size: xx-large;
          font-weight: 900;
          color: gray;
          text-align: right;
          margin-bottom: 8px;
        }

        p {
          font-size: small;
          color: gray;
          text-align: right;
        }
      }

      .perf {
        display: grid;
        grid-template-rows: 1fr auto auto auto;
        gap: 10px;
        height: 100%;
        width: 100%;

        h2 {
          font-size: medium;
          font-weight: 520;
        }

        .hl {
          margin: auto;

          h4 {
            color: orange;
            font-size: x-large;
            font-weight: bolder;
            margin-bottom: 10px;
            text-align: center;
          }

          h5 {
            font-weight: 500;
            color: gray;
            text-align: center;
          }
        }

        .div1auto {
          width: 100%;
          display: grid;
          grid-template-columns: 10px 1fr auto;
          gap: 10px;
          padding: 8px;

          div {
            width: 100%;
            height: 100%;
          }

          p {
            font-size: medium;
          }

          h6 {
            font-size: large;
            font-weight: bolder;
            color: gray;
          }
        }
      }
    }

    .left {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;

      .outer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 1em;

        .ll {
          display: grid;
          grid-template-rows: 1fr 1.5fr;
          gap: 1em;
        }
      }
    }

    .right {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 1em;

      .outer {
        height: 100%;
      }
    }
  }

  .links {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;

    .section {
      border: 1px solid #60606020;
      box-shadow: 1px 1px 5px #60606030;
      padding: 1em;
      cursor: pointer;
      position: relative;
      transition: box-shadow 0.5s ease-in-out;
      position: relative;

      .br{
        width:16px;
        height: 100%;
        background-color: $accent;
        position: absolute;
        left: 0;
        top: 0;
        clip-path: polygon(
          0 0,       /* Top-left corner */
          100% 50%,  /* Middle-right corner before the angle */
          150% 100%, /* Protruding angle */
          100% 50%, /* Bottom-right corner */
          0 100%     /* Bottom-left corner */
      );
      }

      h2 {
        color: $secondary;
        font-weight: 500;
        font-size: medium;
      }
      p {
        font-size: large;
        margin-top: 5px;
      }

      .ic {
        color: $primary;
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
    .section:hover {
      box-shadow: 1px 1px 5px $secondary;
    }
  }
}
